// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";

// Data
import {
  Autocomplete,
  Box,
  Button,
  Grid,
  InputAdornment,
  MenuItem,
  Switch,
  TextField,
  Typography,
} from "@mui/material";

import { useContext, useEffect, useState } from "react";

import { Link, redirect, useNavigate } from "react-router-dom";

import { useFormik } from "formik";
import * as Yup from "yup";
import { Divider } from "rsuite";
import { addPlan } from "lib/Api/Plans";
import { LoginFormContext } from "context/loginFormCtx";
import { VALIDATION_MESSAGES } from "utils/constants";
import { updatePlan } from "lib/Api/Plans";
import { PLAN_TYPE_OPTIONS } from "utils/constants";
import { PLAN_DURATION_TYPE_OPTIONS } from "utils/constants";
import { getAllPlanItems } from "lib/Api/Plans";
import { getFormList } from "lib/Api/FormBuilder";
import { PLAN_TYPES } from "utils/constants";
import { PLAN_DURATION_TYPE } from "utils/constants";

function AddEditPlanForm({ planData }) {
  const { setNotification } = useContext(LoginFormContext);
  const [planItemOptions, setPlanItemOptions] = useState([]);
  const [formOptions, setFormOptions] = useState([]);

  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      name: planData?.name || "",
      description: planData?.description || "",
      plan_type: planData?.plan_type
        ? { value: planData?.plan_type, label: PLAN_TYPES[planData?.plan_type] }
        : null,
      duration: planData?.duration || null,
      plan_duration_type: planData?.plan_duration_type
        ? {
            value: planData?.plan_duration_type,
            label: PLAN_DURATION_TYPE[planData?.plan_duration_type],
          }
        : null,
      price: planData?.price ?? null,
      // discount: planData?.discount || 0,
      // final_price: planData?.final_price || 0,
      per_user_charges: planData?.per_user_charges ?? null,
      forms: planData?.forms || [],
      status: planData?.status || undefined,
      planItems: planData?.planItems || [
        {
          item_id: null,
          quantity: null,
          // price: 0,
        },
      ],
    },
    validationSchema: Yup.object().shape({
      name: Yup.string().required(VALIDATION_MESSAGES.PLANS.NAME.REQUIRED),
      plan_type: Yup.object().required(VALIDATION_MESSAGES.PLANS.TYPE.REQUIRED),
      duration: Yup.number()
        .nullable()
        .optional()
        .when("plan_duration_type", {
          is: (plandurationvalue) => plandurationvalue?.value !== 3,
          then: () =>
            Yup.number()
              .min(1, "Duration must be greater than or equal to 1")
              .required(VALIDATION_MESSAGES.PLANS.DURATION.REQUIRED),
        }),
      plan_duration_type: Yup.object().required(VALIDATION_MESSAGES.PLANS.UNIT.REQUIRED),
      price: Yup.number()
        .nullable()
        .optional()
        .when("plan_type", {
          is: (planvalue) => planvalue?.value === 2,
          then: () => Yup.number().required("Price is required"),
        }),
      // discount: Yup.number().min(0).max(99),
      per_user_charges: Yup.number()
        .nullable()
        .optional()
        .when("plan_type", {
          is: (planvalue) => planvalue?.value !== 2,
          then: () => Yup.number().required("Please enter plan price"),
        }),
      description: Yup.string().required(VALIDATION_MESSAGES.PLANS.DESCRIPTION.REQUIRED),
      forms: Yup.array(),
      planItems: Yup.array().of(
        Yup.object().shape({
          item_id: Yup.object().required("Item is required"),
          quantity: Yup.number()
            .required("Quantity is required")
            .min(1, "Quantity must be at least 1"),
          // price: Yup.number(),
        })
      ),
    }),
    onSubmit: async (values, { setSubmitting, resetForm }) => {
      try {
        setSubmitting(true);

        const temp = {
          ...values,
          status: values.status !== undefined ? +values.status : undefined,
          plan_type: values.plan_type.value,
          plan_duration_type: values.plan_duration_type.value,
          forms: values.forms.map((item) => +item.value),
          planItems: values.planItems.map((item) => {
            return {
              item_id: +item.item_id.value,
              quantity: item.quantity,
              // price: item.price || 0,
            };
          }),
        };

        console.log("temp:101 ", temp);
        let response;
        if (planData) {
          response = await updatePlan(planData.id, temp);
        } else {
          response = await addPlan(temp);
        }

        if (response && response.status === 200) {
          setSubmitting(false);
          resetForm();
          setNotification({
            color: "success",
            icon: "check",
            title: "Success",
            content: response.data.message,
            open: true,
          });
          navigate("/plans/view-plans");
        } else {
          setSubmitting(false);
          setNotification({
            color: "error",
            icon: "cancel",
            title: "Error",
            content: response.data.message,
            open: true,
          });
        }
      } catch (error) {
        console.log("Error: ", error);
        setSubmitting(false);
        setNotification({
          color: "error",
          icon: "cancel",
          title: "Error",
          content: error.message || "Something went wrong!",
          open: true,
        });
      }
    },
  });

  const planItemsOptions = async () => {
    const response = await getAllPlanItems();
    if (response?.status === 200) {
      const temp = response?.data?.data?.rows.map((item) => {
        return {
          value: item.id,
          label: item.name,
        };
      });
      setPlanItemOptions(temp);

      if (planData) {
        const tempPlanItems = planData?.planItems.map((item) => {
          return {
            item_id: temp.find((x) => x.value === item.item_id),
            quantity: item.quantity,
            // price: item.price,
          };
        });
        formik.setFieldValue("planItems", tempPlanItems);
      }
    }
  };

  const formsOptions = async () => {
    const response = await getFormList();
    if (response?.status === 200) {
      const temp = response?.data?.data?.rows.map((item) => {
        return {
          value: item.id,
          label: item.name,
        };
      });
      setFormOptions(temp);

      if (planData) {
        const tempForms = planData?.planForms.map((item) => {
          return temp.find((x) => +x.value === +item.form_id);
        });
        formik.setFieldValue("forms", tempForms);
      }
    }
  };

  useEffect(() => {
    planItemsOptions();
    formsOptions();
  }, []);

  const checkPlanType = formik.values.plan_type?.value;

  const checkPlanDurationType = formik.values.plan_duration_type?.value;



  return (
    <>
      <form onSubmit={formik.handleSubmit} noValidate>
        <Grid container spacing={3}>
          <Grid item md={4} xs={12}>
            <TextField
              type="text"
              value={formik.values.name}
              error={Boolean(formik.touched.name && formik.errors.name)}
              helperText={formik.touched.name && formik.errors.name}
              fullWidth
              label="Name"
              name="name"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              required
            />
          </Grid>
          <Grid item md={4} xs={12} sx={{ height: "10px" }}>
            <Autocomplete
              sx={{ height: "10px" }}
              value={formik.values.plan_type}
              options={PLAN_TYPE_OPTIONS}
              onChange={(event, newValue) => {
                formik.setFieldValue("plan_type", newValue);
                formik.setFieldValue("plan_duration_type", null);
              }}
              placeholder="Select Plan Type"
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Plan Type"
                  error={Boolean(formik.touched.plan_type && formik.errors.plan_type)}
                  helperText={formik.touched.plan_type && formik.errors.plan_type}
                />
              )}
              required
            />
          </Grid>

          <Grid item md={4} xs={12}>
            <Autocomplete
              sx={{ height: "10px" }}
              value={formik.values.plan_duration_type}
              options={
                checkPlanType === 2 ? [{ value: 3, label: "One-off" }] : PLAN_DURATION_TYPE_OPTIONS
              }
              onChange={(event, newValue) => {
                formik.setFieldValue("plan_duration_type", newValue);
              }}
              placeholder="Select Plan Duration Type"
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Plan Duration Type"
                  error={Boolean(
                    formik.touched.plan_duration_type && formik.errors.plan_duration_type
                  )}
                  helperText={formik.touched.plan_duration_type && formik.errors.plan_duration_type}
                />
              )}
              required
            />
          </Grid>

          {checkPlanDurationType !== 3 && (
            <Grid item md={4} xs={12}>
              <TextField
                type="number"
                value={formik.values.duration}
                error={Boolean(formik.touched.duration && formik.errors.duration)}
                helperText={formik.touched.duration && formik.errors.duration}
                fullWidth
                inputProps={{ min: 1 }}
                label="Duration"
                name="duration"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                required
              />
            </Grid>
          )}

          {/* <Grid item md={4} xs={12}>
            <TextField
              type="number"
              value={formik.values.discount}
              error={Boolean(formik.touched.discount && formik.errors.discount)}
              helperText={formik.touched.discount && formik.errors.discount}
              onChange={(event) => {
                formik.setFieldValue("discount", event.target.value);
                const finalPrice =
                  formik.values.price - (formik.values.price * event.target.value) / 100;
                formik.setFieldValue("final_price", finalPrice);
              }}
              fullWidth
              label="Discount"
              name="discount"
              inputProps={{ min: 0, max: 99 }}
              onBlur={formik.handleBlur}
              InputProps={{
                endAdornment: <InputAdornment position="start">%</InputAdornment>,
              }}
            />
          </Grid> */}

          {/* <Grid item md={4} xs={12}>
            <TextField
              type="number"
              value={formik.values.final_price}
              error={Boolean(formik.touched.final_price && formik.errors.final_price)}
              helperText={formik.touched.final_price && formik.errors.final_price}
              fullWidth
              label="Final Price"
              name="final_price"
              disabled
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              InputProps={{
                endAdornment: <InputAdornment position="start">$</InputAdornment>,
              }}
            />
          </Grid> */}

          {checkPlanType !== 2 && (
            <Grid item md={4} xs={12}>
              <TextField
                type="number"
                value={formik.values.per_user_charges}
                error={Boolean(formik.touched.per_user_charges && formik.errors.per_user_charges)}
                helperText={formik.touched.per_user_charges && formik.errors.per_user_charges}
                fullWidth
                label="User Charge"
                name="per_user_charges"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                required
                InputProps={{
                  endAdornment: <InputAdornment position="start">$</InputAdornment>,
                }}
              />
            </Grid>
          )}

          {checkPlanType === 2 && (
            <Grid item md={4} xs={12}>
              <TextField
                type="number"
                value={formik.values.price}
                error={Boolean(formik.touched.price && formik.errors.price)}
                helperText={formik.touched.price && formik.errors.price}
                fullWidth
                label="Price"
                name="price"
                inputProps={{ min: 0 }}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                required
                InputProps={{
                  endAdornment: <InputAdornment position="start">$</InputAdornment>,
                }}
              />
            </Grid>
          )}

          {checkPlanType !== 2 && (
            <Grid item md={4} xs={12}>
              <Autocomplete
                multiple
                sx={{ height: "10px" }}
                value={formik.values.forms}
                options={formOptions}
                onChange={(event, newValue) => {
                  formik.setFieldValue("forms", newValue);
                }}
                placeholder="Forms"
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Forms"
                    error={Boolean(formik.touched.forms && formik.errors.forms)}
                    helperText={formik.touched.forms && formik.errors.forms}
                  />
                )}
                required
              />
            </Grid>
          )}

          <Grid item md={12} xs={12}>
            <TextField
              type="text"
              multiline
              rows={4}
              value={formik.values.description}
              error={Boolean(formik.touched.description && formik.errors.description)}
              helperText={formik.touched.description && formik.errors.description}
              fullWidth
              label="Description"
              name="description"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              required
            />
          </Grid>

          {planData && (
            <Grid item md={4} xs={12}>
              <Grid container>
                <Typography variant="subtitle1">Status:</Typography>
                <Grid item>
                  <Switch
                    checked={formik.values.status}
                    inputProps={{ "aria-label": "controlled" }}
                    name="status"
                    label="Status"
                    onChange={(event) => {
                      formik.setFieldValue(`status`, event.target.checked);
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
          )}

          <Grid item md={12} xs={12}>
            {formik.values.planItems.map((item, index) => {
              return (
                <Box>
                  <Typography variant="h6">Plan Item #{index + 1}</Typography>
                  <Grid container spacing={3}>
                    <Grid item md={4} xs={12}>
                      <Autocomplete
                        sx={{ height: "10px" }}
                        value={formik.values.planItems?.[index].item_id}
                        options={planItemOptions}
                        onChange={(event, newValue) => {
                          formik.setFieldValue(`planItems[${index}].item_id`, newValue);
                        }}
                        placeholder="Plan Item"
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Plan Item"
                            error={Boolean(
                              formik.touched.planItems?.[index].item_id &&
                                formik.errors.planItems?.[index].item_id
                            )}
                            helperText={
                              formik.touched.planItems?.[index].item_id &&
                              formik.errors.planItems?.[index].item_id
                            }
                          />
                        )}
                        required
                      />
                    </Grid>

                    <Grid item md={4} xs={12}>
                      <TextField
                        type="number"
                        value={formik.values.planItems?.[index].quantity}
                        error={Boolean(
                          formik.touched.planItems?.[index].quantity &&
                            formik.errors.planItems?.[index].quantity
                        )}
                        helperText={
                          formik.touched.planItems?.[index].quantity &&
                          formik.errors.planItems?.[index].quantity
                        }
                        fullWidth
                        label="Quantity"
                        name={`planItems[${index}].quantity`}
                        inputProps={{ min: 0 }}
                        onBlur={formik.handleBlur}
                        onChange={(event) => {
                          formik.setFieldValue(`planItems[${index}].quantity`, event.target.value);
                        }}
                        InputProps={{
                          endAdornment: <InputAdornment position="start">GB</InputAdornment>,
                        }}
                        required
                      />
                    </Grid>
                  </Grid>
                </Box>
              );
            })}
          </Grid>
        </Grid>

        <Divider />
        <MDBox sx={{ mt: 2 }} display="flex" justifyContent="center" alignItems="center">
          <Button disabled={formik.isSubmitting} type="submit" sx={{ m: 1 }} variant="contained">
            {planData ? "Save" : "Add Plan"}
          </Button>

          <Link to="/Plans">
            <Button variant="outlined">Cancel</Button>
          </Link>
        </MDBox>
      </form>
    </>
  );
}

export default AddEditPlanForm;
